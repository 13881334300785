.carteEmail {
width: 400px;
  display: flex;
  flex-direction: column;
     @media (max-width: 420px) {
      width: 360px;
    }

  .objetDemande {
    margin-left: 10px;
    width: 90%;
    font-size: 12px;
    margin-top: 20px;
        border  : 1px solid $black;
    border-radius: 20px;

  }
  input {
    margin: 10px;
    padding: 10px;
    height : 30px;
    width : 60%;
    border  : 1px solid $black;
    border-radius: 20px;
  }
  .email-content {
    width: 100%;
       input {
    margin: 10px;
    height : 30px;
    width : 80%;
    border  : 1px solid $black;
  }
  }
  textarea {
    width : 95%;
 height: 275px;
     margin: 10px;
 padding:5px;
 border  : 1px solid $black;
 border-radius: 20px;
  }
  .zonebouton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;

        .boutonoccase {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 30px;
      width: 100px;
      background-color: $green; /* Couleur verte */
      color: $white; /* Texte blanc */
      border: none;
      padding: 15px 20px; /* Espacement interne */
      border-radius: 20px; /* Coins arrondis */
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease; /* Transition de couleur au survol */
    }

    .boutonoccase:hover {
      color: $black; /* Nouvelle couleur verte au survol */
    }
  }
.messagecontact {
  text-align: center;
    font-size: 12px;
    background: $red;
}
 // style des champs obligatoires non remplies
    .error::placeholder {
      color: $white;
      background: $red;
    }
    #not-mail {
          display: none; //non affiché au départ
          position: absolute;
          top: 8px;
          color: $white;
          background: $titreheader;
          right: 0;
          transform-origin: 50% 50%;
          border-radius: 10px;
          width: 150px;
          text-align: center;
        }
}
