.contentModale {
  min-width: 390px;
  max-width: 410px;
  height: 600px;
  background: rgba($white, 0.7);
  border: 5px $grey solid;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15% 85%;
  grid-template-areas:
    "entete"
    "content";
  font-family: $font-1;
  place-items: center center;
  .entetemodal {
    grid-area: entete;
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    background: rgb($blue, 0.4);
    display: grid;
    grid-template-columns: 70% 10% 10% 10%;
    grid-template-rows: 100%;
    grid-template-areas: "titre cancel new savepres";
    place-items: center center;
    .titremodal {
      grid-area: titre;
      font-size: 25px;
      font-weight: bold;

      .escape {
        grid-area: cancel;
      }
      .ajout {
        grid-area: new;
      }
      .save {
        grid-area: savepres;
      }
    }
    .corpsmodale {
      grid-area: content;
      display: grid;
      height: 100%;
      width: 100%;
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      grid-template-areas: "listevalide listeajout";
    }
  }
  .corpsmodale {
    grid-area: content;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "listevalide listeajout";

    .liste {
      grid-area: listevalide;
      padding-left: 10px;
      padding-top: 10px;

      .titreliste {
        font-family: $font-1;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .itemlisteequip {
        display: flex;
        width: 100%;
        overflow-y: auto;
        .ajustitemliste {
          font-size: 13px;
          border-bottom: 1px solid $black;
          margin-bottom: 3px;
        }
      }
    }
    .listeAjout {
      grid-area: listeajout;
      background: rgb(50, 151, 206, 0.4);
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 85% 15%;
      grid-template-areas: "listeEquipement" "ajoutEquipement";

      .AjoutNouvelEquipement {
        width: 100%;
        grid-area: ajoutEquipement;
      }
    }
  }
}
