.contentModaleAgent {

  width: 370px;
  height: 400px;
  background: rgb($white,0.7);
  border: 5px rgba($grey, 0.6) solid;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 12% 85%;
  grid-template-areas:
    "entete"
    "content";
  font-family: $font-1;
  place-items: center center;

  .enteteModalAgent {
    grid-area: entete;
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    background: rgb($blue,0.4);
    display: grid;
    grid-template-columns: 70% 10% 10% 10%;
    grid-template-rows: 100%;
    grid-template-areas: "titre cancel new savepres";
    place-items: center center;
    .titreModalAgent {
      grid-area: titre;
      font-size: 25px;
      font-weight: bold;
    }
    .escape {
      grid-area: cancel;
    }
    .ajoutpresta {
      grid-area: new;
    }
    .savepresta {
      grid-area: savepres;
    }
  }
  .corpsModaleAgent {
    grid-area: content;
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "listevalide listeajout";
    //place-items: center center;
    .listeAgent {
      grid-area: listevalide;
      border-right: 1px solid $black;
      text-align: center;
      font-style: italic;
      .titreliste {
        font-family: $font-1;
        font-size: 18px;
        font-weight: bold;
        border-bottom: 3px solid $black;
      }
      .lignelisteagent {
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $black; // Ajout de la bordure en bas
        padding: 5px;
        font-style: normal;
        .spanlisteagent {
          font-size: 13px;
        }
      }
    }
    .listeAjoutAgent {
      .designationChamps {
        .titrelistemodaleagent {
          padding-top: 5px;
          width: 100%;
          font-size: 12px;
        }
      }
      .messageagent {
      font-size: 10px;
        width :100%;
       border-bottom: 1px solid $black; // Ajout de la bordure en bas 
      }

      .observation {
        font-size: 12px;
        text-align: center;
        font-style: italic;
        background: red;
      }
    }
  }
}
