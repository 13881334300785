.horaire {

  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centre verticalement */
  text-align: center;

  h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .horaires-list {
    display: flex;
    flex-direction: column; /* Arrange les horaires en colonne */
    align-items: center; /* Centre horizontalement */
    margin-bottom: 15px;
  }
  .horaire-item {
    display: flex;
    align-items: center; /* Centre verticalement */
  }

  .horaire-item label {
    margin-right: 10px;
  }
  .boutonoccase {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 60px;
    background-color: $green; /* Couleur verte */
    color: $white; /* Texte blanc */
    border: none;
    padding: 15px 20px; /* Espacement interne */
    border-radius: 20px; /* Coins arrondis */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition de couleur au survol */
  }

  .boutonoccase:hover {
    color: $black; /* Nouvelle couleur verte au survol */
  }
}
