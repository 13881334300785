.containerbodyservice {
  display: flex;
  flex-direction: column; /* Définit la direction en colonne */
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  .imagepage {
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    img {
      height: 80%;
      width: 100%;
    }
  }
  .presentPage {
    display: flex;
    justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    margin-bottom: 2%;
  }
  .servicepage {
    width: 90%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    grid-template-areas:
      "service1"
      "service2";
    @media (max-width: 420px) {
      width: 95%;
    }
    .service1 {
      grid-area: service1;
      width: 100%;
      display: grid;
      align-items: center; /* Centre verticalement */
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      grid-template-areas: "img1 serv1";
      .imge1 {
        grid-area: img1;
      }
      .servi1 {
        grid-area: serv1;
      }
      @media (max-width: 420px) {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
        grid-template-areas: "img1" "serv1";
      }
    }

    .service2 {
      grid-area: service2;
      width: 100%;
      display: grid;
      align-items: center; /* Centre verticalement */
      grid-template-columns: 50% 50%;
      grid-template-rows: 100%;
      grid-template-areas: " serv2 img2";
      .imge2 {
        grid-area: img2;
      }
      .servi2 {
        grid-area: serv2;
      }
      @media (max-width: 420px) {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
        grid-template-areas: "img2" "serv2";
      }
    }
    img {
      margin: 20px;
      max-width: 80%;
      height: auto;

      @media (max-width: 420px) {
      }
      margin: 10px;
      max-width: 100%;
      height: auto;
    }

    .titreservice {
      margin: 40px;
      font-size: 25px;
      font-weight: bold;
      @media (max-width: 420px) {
        margin-left: 0;
        width: 100%;
        text-align: center;
        font-size: 18px;
      }
    }
    .texteservice {
      margin: 40px;
      font-size: 18px;
      @media (max-width: 420px) {
        margin: 30px;
        width: 80%;
        text-align: center;
        font-size: 15px;
      }

    }
  }
}
