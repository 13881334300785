.mainPresentation {

    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
background: linear-gradient(
    to bottom,
    $fond-21,
    $fond-22
  );
  border-radius: 20px;
  padding: 10px;
  .entete {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-rows: 100%;
    grid-template-areas: "titrep iconep";
    display: flex;
    align-items: center; /* Centrer verticalement */
    justify-content: center; /* Centrer horizontalement */

        @media (max-width: 420px) {
  flex-direction: column;
    }
  .iconContainer {
    grid-area: iconep;
        //position: absolute; // Position absolue par rapport à main
        //top: 0px; // Décalage de 10px du haut
        //right: 10px; // Décalage de 10px de la droite
        display: flex; // Permet d'aligner les icônes horizontalement
            align-items: center; /* Centrer verticalement */
            justify-content: center; /* Centrer horizontalement */
    }
  .titrepresentation {
    grid-area: titrep;
    font-size: 25px;
    @media (max-width: 420px) {
      font-size: 15px;
    }
  }

  }

  .messagepresentation {

    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
        @media (max-width: 420px) {
      font-size: 14px;
    }
    textarea {
   font-size: 18px;
   background: transparent; 
      width: 100%; /* Utilise la largeur du conteneur parent */
      height: 100%; /* Utilise la hauteur du conteneur parent */
      resize: none; /* Empêche le redimensionnement manuel du textarea */
      box-sizing: border-box; /* Inclut la largeur et la hauteur dans les dimensions */
      text-align: center;
      border: none; /* Supprime les bordures */
      outline: none; /* Supprime le contour lorsqu'il est sélectionné */
      padding: 5px;
      text-align: center;
        @media (max-width: 420px) {
      font-size: 14px;
    }
      
    }
    &.editable { 
        textarea {
            background-color: rgba($blue, 0.1);
            border: 1px solid $blue;
            border-radius: 20px;
        }
    }
  }
}