.mainfooter {
  width: 100%;
  height: 200px;
  background: $fondfooter;
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  @media (max-width: 420px) {
    height: 500px;
  }
  .containerfooter {
    height: 80%;
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 20% 1fr 30%;
grid-template-rows: 100%;
grid-template-areas:
"logof adressef horairef"
;
@media (max-width: 420px) {
  height : 100%;
  display: grid;
    grid-template-columns: 100%;
grid-template-rows: 33% 1fr 30%;
grid-template-areas:
  "horairef"

 "adressef"
  "logof"
}
    .logofooter {
      grid-area: logof;
      width: 100%;
      min-height: 80px;
      display: flex;
      flex-direction: column; /* Définit la direction en colonne */
      justify-content: center; /* Centre horizontalement */
      align-items: center; /* Centre verticalement */
      justify-content: space-between;
      img {
        max-width: 120px;
      }
      .containericonefooter {
        display: flex;
        justify-content: center; /* Centre horizontalement */
        align-items: center; /* Centre verticalement */
        justify-content: space-between;

        .iconefooter {
          margin: 10px;
          color: $white;
        }
      }
      .createurfooter {
        font-size: 10px;
        margin-top: 20px;
      }
    }
    .adressefooter {
      grid-area: adressef;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Centre verticalement */
      text-align: center;
      h2 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 10px;
      }
    }
    .horairefooter {
      grid-area: horairef;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Centre verticalement */
      text-align: center;
@media (max-width: 420px) {
   margin-top: 15px;
   margin-top: 15px;
  }
    }
  }
}
