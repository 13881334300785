.newEO {
  width: 100%;
  height: 70px;
  .zoneinput {
    display: flex;
    align-items: center;
    width: 80%;
    input {
      padding-left: 5px;
      border-radius: 50px;
      height: 25px;
    }
  }

  .messagenewEO {
    //width: 100%;
    font-size: 10px;
    text-align: center;
    margin: 0;
    margin-top: 5px;
    margin-left: 5px;
    font-weight: bold;
    background: rgba($orange, 0.5);
    border-radius: 20px;
  }
}
