.mainService {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  padding: 10px;

  .enteteserv {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-template-rows: 100%;
    grid-template-areas: "titres iconese";
    display: flex;
    align-items: center; /* Centrer verticalement */
    justify-content: center; /* Centrer horizontalement */

        @media (max-width: 420px) {
  flex-direction: column;
    }
    .titreService {
      grid-area: titres;
      font-size: 23px;
      margin-bottom: 10px;
      @media (max-width: 420px) {
        font-size: 14px;
        font-weight: bold;
      }
    }
  .iconContainer {
   grid-area: iconese;
          display: flex; // Permet d'aligner les icônes horizontalement
            align-items: center; /* Centrer verticalement */
            justify-content: center; /* Centrer horizontalement */
  }

  }

  .messageservice {
    width: 100%;
    height: 200px;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    @media (max-width: 420px) {
      height: 160px;
      font-size: 14px;
    }
    textarea {
      font-size: 17px;
      background: transparent;
      width: 100%; /* Utilise la largeur du conteneur parent */
      height: 100%;/* Utilise la hauteur du conteneur parent */
      resize: none; /* Empêche le redimensionnement manuel du textarea */
      box-sizing: border-box; /* Inclut la largeur et la hauteur dans les dimensions */
      text-align: center;
      border: none; /* Supprime les bordures */
      outline: none; /* Supprime le contour lorsqu'il est sélectionné */
      padding: 5px;
      @media (max-width: 420px) {
        font-size: 14px;
      }
    }
    &.editableservice {
      textarea {
        background-color: rgba($blue, 0.05);
        border: 1px solid $blue;
        border-radius: 20px;
      }
    }
  }
}
