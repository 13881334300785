.mainblog {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb($black, 0.5);
  border-radius: 20px;
  padding: 10px;
  box-shadow: 5px 5px 6px rgba($black, 0.5);
  .titre {
    background: rgb($grey, 0.5);
    font-size: 22px;
    text-align: center;
    height: 25px; // ajuste selon tes besoins
    line-height: 25px; // ajuste selon tes besoins
    font-style: italic;
    border-bottom: 2px solid $black;
    border-radius: 20px;
  }
  .lignenom {
    display: flex;
    //justify-content: center; /* Centre horizontalement */
    align-items: center; /* Centre verticalement */
    .name {
      display: flex;
      align-items: center; /* Centre verticalement */
      font-size: 14px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .date {
      margin-left: 20px;
      font-size: 12px;
    }
  }
  .texte {
    font-size: 12px;
    padding: 5px;
    border-bottom: 1px solid $black;
  }
  .avisvalide {
    margin-top: 10px;
    height: 200px;
    overflow-y: auto;
  }
  .newavis {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    background: rgb($blue,0.3);
    border-radius: 20px;
    input {
      font-size: 14px;
      margin: 5px;
      border: 1px solid $black;
      width: 80%;
      padding: 5px;
      border-radius: 5px;
    }
    textarea {
      font-size: 14px;
      margin: 5px;
      border: 1px solid $black;
      width: 95%;
      padding: 5px;
      border-radius: 5px;
    }

    .rating-container {
      display: flex;
      .soumettre {
        width: 150px;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        background-color: $green; /* Couleur verte */
        color: $white; /* Texte blanc */
        border: none;
        padding: 15px 20px; /* Espacement interne */
        border-radius: 20px; /* Coins arrondis */
        font-size: 16px;
        cursor: pointer;
        margin-right: 10px;
      }
      .soumettre:hover {
        color: $black;
      }
    }
  }
  .avisavalider {
    margin-top: 10px;
    h3 {
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 14px;
      font-style: italic;
    }
    .messageavalider {
      height: 200px;
      overflow-y: auto;
    }
  }
}
