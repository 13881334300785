.containerNavigation {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
@media (max-width: 420px) {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
}
  div {
    width: 120px;
    text-align: center;
    vertical-align: middle;
      font-family: $font-2;
      font-size: 15px;
    a {
      color: $black;
    }
    @media (max-width: 420px) {
      width: 100%;
         font-size: 10px;
    }
  }
}
